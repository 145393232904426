import React, { Component } from 'react';
import '../styles/main.scss';

/**
 * Copy class
 * Displays a textinput
 */
export default class SubTitle extends Component {
    render() {
        const { title } = this.props;
        return <div className="form-flow__field__subtitle">{title}</div>;
    }
}
