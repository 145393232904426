import { IconButton } from '@mui/material';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import Icon from 'components/ui-components-v2/Icon';
import TextField from 'components/ui-components-v2/TextField';
import Button from 'components/ui-components-v2/Button';
import '../styles/main.scss';

const DynamicOptionsList = (props) => {
    const [options, setOptions] = useState(props.options || { emptyOption: 'Empty Option' });

    /**
     * Camelize a string
     * @param {*} string the string to be camalized
     * @returns camelized string
     */
    const camelize = (string) => {
        return string
            .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
                return index === 0 ? word.toLowerCase() : word.toUpperCase();
            })
            .replace(/\s+/g, '');
    };

    const onOptionInput = (e, index, target) => {
        //Convert object to an array
        const optionsArr = typeof options === 'object' ? Object.entries(options) : options;

        //Update the 'key and value' of the array
        //change key
        if (target === 'key') optionsArr[index].splice(0, 1, camelize(e.target.value));
        //change the key to the camelized value if the key is empty or if the key equals the camelized value
        if (target === 'value' && (optionsArr[index][0] === '' || camelize(optionsArr[index][1]) === camelize(optionsArr[index][0])))
            optionsArr[index].splice(0, 1, camelize(e.target.value));
        //change the value
        if (target === 'value') optionsArr[index].splice(1, 1, e.target.value);

        //Convert the array back to an object
        const optionsObj = Object.fromEntries(optionsArr);

        //set the state to the updated object
        setOptions(optionsObj);
        props.onChange(props.model, optionsObj);
    };

    const removeOption = (key) => {
        const _options = cloneDeep(options);

        delete _options[key];

        setOptions(_options);
        props.onChange(props.model, _options);
    };

    const addOption = () => {
        const _options = cloneDeep(options);
        _options['emptyOption'] = 'Empty Option';

        setOptions(_options);
        props.onChange(props.model, _options);
    };

    return (
        <div className="form-flow__field__dynamic-options-list">
            <ul>
                {typeof options === 'object' &&
                    options !== null &&
                    Object.keys(options).map((key, i) => {
                        return (
                            <li key={i}>
                                <TextField
                                    value={options[key]}
                                    onChange={(e) => onOptionInput(e, i, 'value')}
                                    helperText="value"
                                    className="form-flow__field__dynamic-options-list__value"
                                />
                                <TextField
                                    value={key}
                                    onChange={(e) => onOptionInput(e, i, 'key')}
                                    helperText="key"
                                    className="form-flow__field__dynamic-options-list__key"
                                />
                                <IconButton onClick={() => removeOption(key)} size="small" className="form-flow__field__dynamic-options-list__delete">
                                    <Icon>delete</Icon>
                                </IconButton>
                            </li>
                        );
                    })}
            </ul>
            <Button className="form-flow__field__dynamic-options-list__add-option" onClick={addOption} color="primary" size="small" variant="outlined">
                Add option
            </Button>
        </div>
    );
};

export default DynamicOptionsList;
