import RadioGroup from './components';
import  FieldSetWrapper  from '../../components/fieldset';

const FieldSetRadioGroup = FieldSetWrapper(RadioGroup);

export default FieldSetRadioGroup;
export {
    RadioGroup,
    FieldSetRadioGroup
}
