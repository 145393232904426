import React from 'react';
import PropTypes from 'prop-types';
import InlineAlert from 'components/ui-components/InlineAlert';

const AlertBox = ({ value, alertType }) => {
    return <InlineAlert type={alertType}>{value}</InlineAlert>;
};

AlertBox.propTypes = {
    value: PropTypes.string,
    alertType: PropTypes.oneOf(['success', 'info', 'alert'])
};

AlertBox.defaultProps = {
    value: '',
    alertType: 'info'
};

export default AlertBox;
