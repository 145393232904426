import React, { Component } from 'react';
import MappingFields from './mapping-fields';
import '../styles/main.scss';

/**
 * FieldMap class
 * Displays a row source items and input fields
 * This is used to map the value of the dynamic templates to the values of the new records in the FeedManager
 */
export default class FieldMap extends Component {
    static defaultProps = {
        fieldLabel: 'Field',
        fieldOptions: null,
        valueLabel: 'Value',
        valueOptions: null,
        hasComparison: false,
        canAdd: false
    };

    constructor(props) {
        super(props);

        const value = this.transformValue(props.value, props.hasComparison);

        this.state = { value };
    }

    onChangeModel = () => {
        const { model, onChange, hasComparison } = this.props;
        let { value } = this.state;

        if (hasComparison) {
            // Remove values with a missing field
            value = value.filter((v) => v.comparison && v.field && v.field.length > 0 && v.value && v.value.length > 0);
        } else {
            // Remove values with a missing field
            value = value.filter((v) => v.field && v.field.length > 0 && v.value && v.value.length > 0);

            // Let's loose the comparison field.
            value = value.map((v) => ({ field: v.field, value: v.value }));
        }
        onChange(model, value);
    };

    /**
     * Transforms an object to the correct array format.
     * If used without comparison (!hasComparison) we recieve an object that should be converted to an array
     * @param {*} value
     * @param {boolean} hasComaparison
     */
    transformValue = (value, hasComparison) => {
        if (!value) {
            return [
                {
                    field: '',
                    comparison: null,
                    value: ''
                }
            ];
        } else {
            return value;
        }
    };

    addInputRow = () => {
        const { value } = this.state;

        const newValue = [...value];

        newValue.push({
            field: '',
            comparison: null,
            value: ''
        });

        this.setState({ value: newValue }, () => this.onChangeModel());
    };

    removeInputRow = (i) => {
        const { value } = this.state;

        const newValue = [...value];
        newValue.splice(i, 1);

        this.setState({ value: newValue }, () => this.onChangeModel());
    };

    onChangeValue = (value) => {
        this.setState({ value }, () => this.onChangeModel());
    };

    render() {
        const { fieldLabel, fieldOptions, valueLabel, valueOptions, hasComparison, canAdd } = this.props;
        const { value = [] } = this.state;

        return (
            <MappingFields
                value={value}
                fieldLabel={fieldLabel}
                fieldOptions={fieldOptions}
                valueLabel={valueLabel}
                valueOptions={valueOptions}
                hasComparison={hasComparison}
                canAdd={canAdd}
                onChange={this.onChange}
                onChangeValue={this.onChangeValue}
                addInputRow={this.addInputRow}
                removeInputRow={this.removeInputRow}
            />
        );
    }
}
