import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MuiAutocomplete from '@mui/material/Autocomplete';
import TextField from 'components/ui-components-v2/TextField';
import Checkbox from 'components/ui-components-v2/Checkbox';
import FormFlowHelpers from 'components/data/FormFlowHelpers';

/**
 * Get Options
 * This class prepares the options in the form.
 * Various formats are supported:
 * - K/V Pairs as an object
 * - Objects with id/title fields
 * - Objects with id/name fields
 * - Objects with value/label fields
 */
const getFormattedOptions = (options, categorized) => {
    const newOptions = FormFlowHelpers.standardizeOptions(options);
    if (!newOptions.length || !categorized) return newOptions;
    let categorizedOptions;
    if (newOptions[0].category) {
        categorizedOptions = newOptions;
    } else {
        // If no categories are found, group by first letter.
        categorizedOptions = newOptions.map((option) => {
            const firstLetter = option.label[0].toUpperCase();
            return {
                category: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                ...option
            };
        });
    }
    categorizedOptions.sort((a, b) => -b.category.localeCompare(a.category));
    return categorizedOptions;
};

/**
 * Determine if an option is selected. We can work with strings or objects like { value, label }
 * @param {*} option
 * @param {*} value
 * @returns true is this option is in the value.
 */
const getOptionSelected = (option, value) => {
    if (typeof option === 'string') {
        if (option === value) return true;
    } else if (option.value) {
        if (option.value === value.value) return true;
    }
    return false;
};

const getOptionLabel = (option, options) => {
    if (option.label) return option.label;
    const found = options.find((o) => o.value === option);
    if (found && found.label) return found.label;
    return option;
};

/**
 * Filter out values that are not in the option list
 * @param {*} value
 * @param {array} options
 * @returns
 */
const getFormattedValue = (value, options, inputType, outputType, noModel, acceptsNewValues) => {
    const multiple = inputType === 'multiple' || inputType === 'checkbox';

    if (!value) return multiple ? [] : null;

    if (noModel) return value;

    if (outputType === 'keyvalue') {
        let valueArray = [];
        // The value will be an object with key value pairs, we have to transform this to an array.
        if (Array.isArray(value)) {
            valueArray = value.map((v) => ({ value: v.key, label: v.value }));
        } else {
            Object.entries(value).forEach(([k, v]) => valueArray.push({ value: k, label: v }));
        }
        return valueArray;
    }

    if (multiple && !acceptsNewValues) return removeUnknownOptionsFromValue(value, options);

    return value;
};

/**
 * Remove items form a value array that are not known in the options list.
 * @param {array} value
 * @param {array} options
 * @returns
 */
const removeUnknownOptionsFromValue = (value, options) => {
    const parsedValue = value.filter((item) => {
        if (typeof options[0] === 'string') {
            if (!options.includes(item)) return false;
        } else if (options.filter((possibleOption) => possibleOption.label === item.label).length === 0) return false;

        return true;
    });

    return parsedValue;
};

/**
 * Render the chosen options as a string.
 * @param {array} selected
 * @returns
 */
const renderCompact = (selected) => {
    let result = '';

    try {
        // Only show selected if its in the list of possible values
        selected.forEach((option) => {
            const newString = option.label ? option.label : option;
            result += ', ' + newString;
        });
        result = result.substring(2);
    } catch (e) {}

    return <span>{result}</span>;
};

const Autocomplete = ({
    value,
    model,
    options,
    label,
    placeholder,
    inputType,
    outputType,
    categorized,
    compact,
    onChange,
    freeSolo,
    noModel,
    acceptsNewValues
}) => {
    const [formattedOptions, setFormattedOptions] = useState(getFormattedOptions(options, categorized));
    const [formattedValue, setFormattedValue] = useState(getFormattedValue(value, options, inputType, outputType, noModel, acceptsNewValues));

    /**
     * On change item
     * Sets the parent using onMutation
     */
    const handleChange = (_e, v) => {
        setFormattedValue(v);
        if (freeSolo) {
            _e.preventDefault();
        }

        if (noModel) {
            onChange(v);
            return;
        }

        if ((inputType === 'checkbox' || inputType === 'multiple') && outputType === 'keyvalue') {
            const output = {};
            v.forEach((item) => {
                if (options[item.value]) {
                    output[item.value] = options[item.value];
                } else if (acceptsNewValues) {
                    if (typeof item === 'object') {
                        output[item.value] = item.label;
                    } else if (typeof item === 'string') {
                        output[item.toLowerCase().trim().replaceAll(' ', '_')] = item;
                    }
                }
            });
            onChange(model, output);
        } else {
            onChange(model, v);
        }
    };

    useEffect(() => {
        setFormattedOptions(getFormattedOptions(options, categorized));
    }, [options]);

    useEffect(() => {
        setFormattedValue(getFormattedValue(value, options, inputType, outputType, noModel, acceptsNewValues));
    }, [value]);

    return (
        <MuiAutocomplete
            multiple={inputType === 'checkbox' || inputType === 'multiple'}
            value={formattedValue}
            options={formattedOptions}
            getOptionLabel={(option) => getOptionLabel(option, formattedOptions)}
            isOptionEqualToValue={(option, value) => getOptionSelected(option, value, outputType)}
            onChange={(e, v) => handleChange(e, v)}
            freeSolo={freeSolo}
            style={{ width: '100%' }}
            renderOption={(props, option, { selected }) => {
                return (
                    <li {...props}>
                        {(inputType === 'checkbox' || inputType === 'multiple') && <Checkbox style={{ marginRight: 8 }} checked={selected} />}
                        {option.label ? option.label : option}
                    </li>
                );
            }}
            renderTags={compact ? (values) => renderCompact(values) : undefined}
            renderInput={(params) => <TextField {...params} style={{ width: '100%' }} label={label} variant="outlined" placeholder={placeholder} />}
            groupBy={categorized ? (option) => option.category : () => {}}
        />
    );
};

Autocomplete.propTypes = {
    value: PropTypes.any,
    onMutation: PropTypes.func,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]), // Predefined options to choose from
    defaultValue: PropTypes.string,
    inputType: PropTypes.oneOf(['multiple', 'checkbox', 'single']), // 'checkbox' equals 'multiple' for legacy purposes.
    outputType: PropTypes.string,
    compact: PropTypes.bool,
    categorized: PropTypes.bool,
    freeSolo: PropTypes.bool,
    noModel: PropTypes.bool, // This is used for components that use this autocomplete without a model and only want the returned value
    acceptsNewValues: PropTypes.bool
};

Autocomplete.defaultProps = {
    onMutation: () => {},
    options: [],
    inputType: 'multiple',
    outputType: undefined,
    value: null,
    placeholder: '',
    compact: false,
    categorized: false,
    freeSolo: false,
    noModel: false,
    acceptsNewValues: false
};

export default Autocomplete;
