import Richtext from './components';
import  FieldSetWrapper  from '../../components/fieldset';

const FieldSetRichtext = FieldSetWrapper(Richtext);

export default FieldSetRichtext;
export {
    Richtext,
    FieldSetRichtext
}
