import React from 'react';
import { withRouter } from 'react-router';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import SimpleReactValidator from 'simple-react-validator';
import Icon from 'components/ui-components-v2/Icon';
import Dialog from 'components/ui-components-v2/Dialog';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import Button from 'components/ui-components-v2/Button';
import Request from 'components/data/Request';
import Translation from 'components/data/Translation';
import AlertBox from 'components/input/AlertBox';
import { FormFlowProvider } from './context';
import Section from './section';
import Route from '../../RouteHelper';
import SnackbarUtils from '../../SnackbarUtils';
import './../styles/index.scss';

/**
 * Form Class
 * This is the main class for the form flow.
 * It loads and saved the data and integrates the form.
 */
class Form extends React.Component {
    _isMounted = false;

    static propTypes = {
        id: PropTypes.string, // The ID in case we are editing. (optional)
        data: PropTypes.any, // The initial data the is used in the form
        getApi: PropTypes.string, // The API (standardized format) for fetching existing items. (optional)
        setup: PropTypes.array, // This is the setup of the form. The setup consists of a an array with sections. Further defined on Nuclino.
        submitButtonLabel: PropTypes.string, // The text to be displayed in the save button
        saveApi: PropTypes.string, // The API (standardized format) for saving data
        saveNotification: PropTypes.string, // The notification displayed after saving
        redirectAfterSave: PropTypes.string, // The url to redirect the user to after saving
        loading: PropTypes.bool, // If true, show loader
        saving: PropTypes.bool, // If true, show loader in button
        view: PropTypes.string, // Can be empty (form), 'modal' This is a dialog or 'modal-form' this is the same as the modal view but is used in a wrapping dialog
        hideCancel: PropTypes.bool, // Hides the cancel button used in the modal and modal-form views
        modalOpen: PropTypes.bool, // Open modal
        title: PropTypes.string, // Title of the modal in case a modal is used
        buttonWidth: PropTypes.string, // The width of the button in the form
        onBeforeSave: PropTypes.func, // A function being called before saving. This function passes the data. The function should return the data that is transformed
        onSubmitComplete: PropTypes.func, // A function being called after saving is completed. This function passes the data as well. This function can be used for instance to show a message, open a dialog etc.
        onSubmit: PropTypes.func, // In case we don't use get saveAPI prop, we can also pass a function. The function should return a promise that can be completed. After completion a message is shown.
        onGet: PropTypes.func, // In case we don't use the getAPI, we can also pass a function. The function returns the item data.
        onCloseDialog: PropTypes.func, // Close dialog
        onChange: PropTypes.func, // On update the form, run this function
        onChangeTransform: PropTypes.func, // On update the form, run this function
        disableDialogActions: PropTypes.bool, //if true the dialog actions won't apear in the dialog,
        disableSubmit: PropTypes.bool, //Disables the submit of the form and just calls the function
        removeValueOnHide: PropTypes.bool, //if true the input returns undefined for inputs that are hidden becouse of the condition not being met,
        showSaveNotification: PropTypes.bool, // If true show save notifitcation
        submitButtonDataCySuffix: PropTypes.bool
    };

    constructor(props) {
        super(props);

        // Get id
        let id = 0;
        if (this.props.match && this.props.match.params.id) {
            id = this.props.match.params.id;
        }
        if (props.id) {
            id = this.props.id;
        }

        // Initialize the validator
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            element: (message, className) => {
                return (
                    <div className="form-flow__validation__message" data-cy={className}>
                        {message}
                    </div>
                );
            },
            validators: {
                password: {
                    message: Translation.get('validator.password', 'common'),
                    rule: (val, params, validator) => {
                        // eslint-disable-next-line no-useless-escape
                        return validator.helpers.testRegex(
                            val,
                            /^(?:(?=.*\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))(?!.*(.)\1{2,})[A-Za-z0-9!~<>,;:_=?*+#."&§%°()\|\[\]\-\$\^\@\/]{8,32}$/i
                        ); //
                    }
                },
                hasUpperCase: {
                    message: Translation.get('validator.hasUpperCase', 'common'),
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /[A-Z]/);
                    }
                },
                hasLowerCase: {
                    message: Translation.get('validator.hasLowerCase', 'common'),
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /[a-z]/);
                    }
                },
                hasSpecial: {
                    message: Translation.get('validator.hasSpecial', 'common'),
                    rule: (val, params, validator) => {
                        // eslint-disable-next-line no-useless-escape
                        return validator.helpers.testRegex(val, /[@!#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/);
                    }
                },
                hasNumbers: {
                    message: Translation.get('validator.hasNumbers', 'common'),
                    rule: (val, params, validator) => {
                        // eslint-disable-next-line no-useless-escape
                        return validator.helpers.testRegex(val, /\d/);
                    }
                }
            },
            messages: {
                accepted: Translation.get('validator.accepted', 'common'),
                after: Translation.get('validator.after', 'common'),
                after_or_equal: Translation.get('validator.after_or_equal', 'common'),
                alpha: Translation.get('validator.alpha', 'common'),
                alpha_space: Translation.get('validator.alpha_space', 'common'),
                alpha_num: Translation.get('validator.alpha_num', 'common'),
                alpha_num_space: Translation.get('validator.alpha_num_space', 'common'),
                alpha_num_dash: Translation.get('validator.alpha_num_dash', 'common'),
                alpha_num_dash_space: Translation.get('validator.alpha_num_dash_space', 'common'),
                array: Translation.get('validator.array', 'common'),
                before: Translation.get('validator.before', 'common'),
                before_or_equal: Translation.get('validator.before_or_equal', 'common'),
                between: Translation.get('validator.between', 'common'),
                boolean: Translation.get('validator.boolean', 'common'),
                card_exp: Translation.get('validator.card_exp', 'common'),
                card_num: Translation.get('validator.card_num', 'common'),
                currency: Translation.get('validator.currency', 'common'),
                date: Translation.get('validator.date', 'common'),
                date_equals: Translation.get('validator.date_equals', 'common'),
                email: Translation.get('validator.email', 'common'),
                in: Translation.get('validator.in', 'common'),
                integer: Translation.get('validator.integer', 'common'),
                max: Translation.get('validator.max', 'common'),
                min: Translation.get('validator.min', 'common'),
                not_in: Translation.get('validator.not_in', 'common'),
                not_regex: Translation.get('validator.not_regex', 'common'),
                numeric: Translation.get('validator.numeric', 'common'),
                phone: Translation.get('validator.phone', 'common'),
                regex: Translation.get('validator.regex', 'common'),
                required: Translation.get('validator.required', 'common'),
                size: Translation.get('validator.size', 'common'),
                string: Translation.get('validator.string', 'common'),
                typeof: Translation.get('validator.typeof', 'common'),
                url: Translation.get('validator.url', 'common')
            }
        });

        this.state = {
            data: props.data ? props.data : {},
            id: id,
            loading: false,
            saving: false
        };
    }

    componentDidUpdate = (prevProps) => {
        // Change internal state when externally triggered
        if (prevProps.loading !== this.props.loading) {
            this.setState({ loading: this.props.loading });
        }

        if (prevProps.saving !== this.props.saving) {
            this.setState({ saving: this.props.saving });
        }

        // Setup the form in case the setup changed
        if (prevProps.setup !== this.props.setup) {
            this.renderForm();
        }

        // Allow external updates
        if (prevProps.data !== this.props.data) {
            this.setState({ data: this.props.data });
        }
    };

    componentDidMount = () => {
        this._isMounted = true;
        const { id } = this.state;

        if (id !== 0) {
            this.getItemData();
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * Get Item Data
     * Received the item data from the API
     */
    getItemData = () => {
        const { getApi, onGet } = this.props;
        const { id } = this.state;

        const _this = this;

        // We have a predefined function for getting the data
        if (onGet) {
            _this.setState({ data: onGet(), loading: false });
        }

        // We have a GET api. Use that function to get the data
        if (getApi) {
            this.setState({ loading: true, saving: false });

            // Fetch data from the server
            Request.post(getApi, { id: id }).then(
                (result) => {
                    _this.setState({ data: result.data, loading: false });
                },
                (error) => {
                    SnackbarUtils.error("The item data couldn't be loaded. ");
                    console.error('There was an error getting the item', error);
                }
            );
        }
    };

    /**
     * Handle data changes
     * When a field is updated this handler is called
     * @param {string} model The model that is called
     * @param {mixed} value The value for the model
     */
    handleChange = (model, value) => {
        let data = this.state.data;
        let activeObject = data;
        const modelParts = model.split('.');
        const { onChange = () => { }, onChangeTransform } = this.props;

        // Loop through items and write value
        modelParts.forEach((part, i) => {
            let activeObjectSet = false;

            // We want to replace an array
            if (part && part.includes('[')) {
                activeObjectSet = true;
                const partItems = part.split(/\[|\]/);

                // Create empty array in case it doesn't exist
                if (!activeObject[partItems[0]] || !Array.isArray(activeObject[partItems[0]])) {
                    activeObject[partItems[0]] = [];
                }
                activeObject = activeObject[partItems[0]][parseInt(partItems[1])];
            }
            // The object doesn't exist
            else if (typeof activeObject[part] !== 'object' || activeObject[part] === null || Array.isArray(activeObject[part])) {
                activeObject[part] = {};
            }

            if (modelParts.length - 1 === i) {
                activeObject[part] = value;
            }

            // Set active object
            if (!activeObjectSet) {
                activeObject = activeObject[part];
            }
        });

        // In case we have a tranform function, use that
        if (onChangeTransform) {
            data = onChangeTransform(data);
        }

        this.setState({ data: data }, () => onChange(model, value));
    };

    /**
     * Render form
     * Starts rendering the entire form
     * This renders all sections
     */
    renderForm = () => {
        const { setup, variant, removeValueOnHide = false } = this.props;
        const { data } = this.state;

        if (!setup || !setup.map) {
            console.error('Fields not correctly defined. ');
            return <React.Fragment></React.Fragment>;
        }
        return (
            <FormFlowProvider
                value={{
                    data: data,
                    validator: this.validator
                }}>
                {setup.map((item, i) => (
                    <Section key={'section' + i} variant={variant} onChange={this.handleChange} removeValueOnHide={removeValueOnHide} {...item} />
                ))}
            </FormFlowProvider>
        );
    };

    /**
     * Save data to the api
     */
    handleSubmit = (event) => {
        const { saveApi, redirectAfterSave, onBeforeSave, onSubmit, onSubmitComplete, saveNotification, showSaveNotification = true } = this.props;
        let data = this.state.data;

        const _this = this;
        // Check validations
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            SnackbarUtils.error('The form contains errors. Please check again. ', 4000);
            event.preventDefault();
            return;
        }

        if (onBeforeSave) {
            data = onBeforeSave(data);
        }

        // We have a save prop, which returns a promise.
        let promise;
        if (onSubmit) {
            promise = onSubmit(data);
            if (promise) {
                this._isMounted && this.setState({ saving: true });
            }
        }
        // We have an API, use our request
        else if (saveApi) {
            this._isMounted && this.setState({ saving: true });
            promise = Request.post(saveApi, { id: this.state.id, data: data });
        } else {
            console.error('No action defined when saving. ');
        }

        // We have a promise, wait before finishing it
        if (promise) {
            promise.then(
                // The promise was successfull. This shows the link or uses the save complete function.
                (result) => {
                    _this._isMounted && _this.setState({ loading: false, saving: false });
                    if (showSaveNotification) SnackbarUtils.success(saveNotification ? saveNotification : 'The data was successfully saved. ');

                    if (result && result.id) {
                        data.id = result.id;
                    }
                    if (result && result.data && result.data.id) {
                        data.id = result.data.id;
                    }

                    if (onSubmitComplete) {
                        data = onSubmitComplete(data);
                    }

                    if (redirectAfterSave) {
                        Route.openLink(redirectAfterSave);
                    }
                },
                // The promise failed, show the error
                (error) => {
                    SnackbarUtils.error("We couldn't save the data. ");
                    console.error('There was an error saving the data', error);
                    _this._isMounted && _this.setState({ loading: false, saving: false });
                }
            );
        }

        event.preventDefault();
    };

    // Render
    render() {
        const {
            submitButtonLabel = Translation.get('actions.save', 'common'),
            error,
            modalOpen = false,
            onCloseDialog,
            title,
            view = 'form',
            variant,
            disableDialogActions = false,
            buttonWidth,
            disableSubmit,
            hideCancel,
            submitButtonDataCySuffix
        } = this.props;
        const { loading, saving } = this.state;

        // Make sure that only the fields that are rendered will be validated
        this.validator.purgeFields();

        // Show in modal
        if (view === 'modal') {
            return (
                <Dialog onClose={onCloseDialog} open={modalOpen} fullWidth={true} maxWidth={'sm'}>
                    <DialogTitle style={{ display: 'flex', padding: '12px', alignItems: 'center' }}>
                        <Typography variant="h4" style={{ flexGrow: 1 }}>
                            {title}
                        </Typography>
                        <IconButton onClick={onCloseDialog} size="small">
                            <Icon>close</Icon>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers style={{ padding: 0 }}>
                        <div className="form-flow form-flow--modal">
                            {loading && <CircularProgress />}
                            {!loading && (
                                <React.Fragment>
                                    {error && <AlertBox alertType="error" message={error} />}

                                    <form onSubmit={this.handleSubmit}>{this.renderForm()}</form>
                                </React.Fragment>
                            )}
                        </div>
                    </DialogContent>
                    {!disableDialogActions && (
                        <DialogActions style={{ height: '64px' }}>
                            {!saving && (
                                <React.Fragment>
                                    {!hideCancel && <Button onClick={onCloseDialog}>{Translation.get('actions.cancel', 'common')}</Button>}
                                    <Button
                                        data-cy={'formflow-submit-button-' + submitButtonDataCySuffix}
                                        type={disableSubmit ? 'button' : 'submit'}
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleSubmit}>
                                        {submitButtonLabel}
                                    </Button>
                                </React.Fragment>
                            )}
                            {saving && <CircularProgress size={32} />}
                        </DialogActions>
                    )}
                </Dialog>
            );
        } else if (view === 'modal-form') {
            return (
                <React.Fragment>
                    <DialogContent dividers style={{ padding: 0 }}>
                        <div className="form-flow form-flow--modal form-flow--modal-form">
                            {loading && <CircularProgress />}
                            {!loading && (
                                <React.Fragment>
                                    {error && <AlertBox alertType="error" message={error} />}

                                    <form onSubmit={this.handleSubmit}>{this.renderForm()}</form>
                                </React.Fragment>
                            )}
                        </div>
                    </DialogContent>
                    {!disableDialogActions && (
                        <DialogActions style={{ height: '64px', paddingRight: '24px', paddingLeft: '24px' }}>
                            {!saving && (
                                <React.Fragment>
                                    {!hideCancel && <Button onClick={onCloseDialog}>{Translation.get('actions.cancel', 'common')}</Button>}
                                    <Button
                                        data-cy={'formflow-submit-button-' + submitButtonDataCySuffix}
                                        type={disableSubmit ? 'button' : 'submit'}
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleSubmit}>
                                        {submitButtonLabel}
                                    </Button>
                                </React.Fragment>
                            )}
                            {saving && <CircularProgress size={32} />}
                        </DialogActions>
                    )}
                </React.Fragment>
            );
        }

        // Show in page
        else {
            return (
                <div className="form-flow">
                    {loading && <CircularProgress />}
                    {!loading && (
                        <React.Fragment>
                            {error && <AlertBox alertType="error" message={error} />}

                            <form onSubmit={this.handleSubmit}>
                                {this.renderForm()}
                                {variant !== 'compact' && (
                                    <LoadingButton
                                        data-cy={'formflow-submit-button-' + submitButtonDataCySuffix}
                                        type={disableSubmit ? 'button' : 'submit'}
                                        variant="contained"
                                        color="primary"
                                        fullWidth={buttonWidth && buttonWidth == 'full' ? true : false}
                                        loading={saving}
                                        onClick={this.handleSubmit}>
                                        {submitButtonLabel}
                                    </LoadingButton>
                                )}
                            </form>
                        </React.Fragment>
                    )}
                </div>
            );
        }
    }
}

export default withRouter(Form);
