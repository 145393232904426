import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Autocomplete } from '../../Autocomplete';
import '../styles/main.scss';

/**
 * Select class
 * Displays a select box
 */
export default class ChipField extends Component {
    static propTypes = {
        value: PropTypes.string,
        options: PropTypes.any, // The differrent options as object or array
        readOnly: PropTypes.bool,
        maxLength: PropTypes.number
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value && typeof props.value !== 'string' ? props.value : []
        };
    }

    onChange = (value) => {
        const { onChange, model } = this.props;
        onChange(model, value);
    };

    render() {
        const { readOnly } = this.props;
        const { value = [] } = this.state;

        // Read only, show value
        if (readOnly) {
            return <div>{value.join()}</div>;
        }
        // Not read only, show inout
        else {
            return (
                <div className="form-flow__field__chips">
                    <Autocomplete freeSolo value={value} options={[]} onChange={(v) => this.onChange(v)} noModel></Autocomplete>
                </div>
            );
        }
    }
}
