import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import DOMPurify from 'dompurify';
import '../styles/main.scss';
const CKEditor = React.lazy(() => import('./editor-lazy'));

/**
 * Richtext class
 * Displays a richtext field
 */
export default class Richtext extends Component {
    static propTypes = {
        value: PropTypes.string,
        maxLength: PropTypes.number
    };

    constructor(props) {
        super(props);

        const allOptions = [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'imageUpload',
            'blockQuote',
            'insertTable',
            'mediaEmbed',
            'undo',
            'redo'
        ];
        let toolbar = allOptions;
        if (props.includedOptions) {
            toolbar = props.includedOptions;
        } else if (props.excludedOptions) {
            toolbar = allOptions.filter((x) => !props.excludedOptions.includes(x));
        }

        this.state = {
            value: props.value,
            toolbar: toolbar
        };
    }

    /**
     * Change input
     * Renders the local state and sends the new value up
     */
    onChange = (event, editor) => {
        const { onChange, model } = this.props;
        const value = editor.getData();

        this.setState({ value: value });
        onChange(model, value);
    };

    render() {
        const { readOnly, disabled } = this.props;
        const { value = '', toolbar } = this.state;

        // Read only, show value
        if (readOnly) {
            return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }} />;
        }
        // Not read only, show inout
        else {
            return (
                <div className="form-flow__field__richtext">
                    <Suspense fallback={<div>Loading...</div>}>
                        <CKEditor
                            data={value}
                            disabled={disabled}
                            config={{
                                toolbar: toolbar
                            }}
                            onInit={(editor) => {
                                // You can store the "editor" and use when it is needed.
                            }}
                            onChange={this.onChange}
                        />
                    </Suspense>
                </div>
            );
        }
    }
}
