import FieldMap from './components';
import  FieldSetWrapper  from '../../components/fieldset';

const FieldSetFieldMap = FieldSetWrapper(FieldMap);

export default FieldSetFieldMap;
export {
    FieldMap,
    FieldSetFieldMap
}
