import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Switch from 'components/ui-components-v2/Switch';

/**
 * SwitchField
 * Displays a switch in the interface.
 */
export default class SwitchField extends Component {
    static propTypes = {
        /** Function which gets called when the switch has changed */
        onChange: PropTypes.func,
        /** Current value of the picker */
        value: PropTypes.any,
        /** Change styling */
        variant: PropTypes.oneOf(['compact']),
        defaultValue: PropTypes.bool
    };

    static defaultProps = {
        onChange: () => {},
        defaultValue: false
    };

    constructor(props) {
        super(props);

        this.state = {
            value: typeof props.value === 'boolean' ? props.value : props.defaultValue
        };
    }

    /**
     * OnChange
     * Change the input of the switch, so set state and mutate.
     */
    onChange = (event) => {
        const { onChange, model } = this.props;

        onChange(model, !this.state.value);
        this.setState({ value: !this.state.value });
    };

    /**
     * Component updated.
     * Check whether the props require a new state.
     */
    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.value !== prevProps.value) {
            this.setState({ value: this.props.value });
        }
    };

    render() {
        const { variant, title } = this.props;
        const { value = false, readOnly = false } = this.state;
        return (
            <label className="switch-field-set">
                {variant === 'compact' && <div>{title}</div>}
                <Switch onClick={this.onChange} checked={value} disabled={readOnly} />
            </label>
        );
    }
}
