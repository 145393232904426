import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FormControl from '@mui/material/FormControl';
import Icon from 'components/ui-components-v2/Icon';
import Select from 'components/ui-components-v2/Select';
import FormFlowHelpers from 'components/data/FormFlowHelpers';
import '../styles/main.scss';

/**
 * Select class
 * Displays a select box
 */
export default class SelectField extends Component {
    static propTypes = {
        value: PropTypes.string,
        options: PropTypes.any, // The differrent options as object or array
        readOnly: PropTypes.bool,
        extended: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value ? props.value : ''
        };
    }

    componentDidUpdate = (prevProps) => {
        //Update the value externally
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    };

    /**
     * Get Options
     * This class prepares the options in the form.
     * Various formats are supported:
     * - K/V Pairs as an object
     * - Objects with id/title fields
     * - Objects with id/name fields
     * - Objects with value/label fields
     */
    getOptions = () => {
        const { options: propOptions, model, onChange } = this.props;
        const { value } = this.state;
        let options = [];

        // We have an empty value. Add the empty option as well to prevent an incorrect value from being displayed in the list.
        if (!value) {
            options.push({ value: '', label: '' });
        }

        options = FormFlowHelpers.standardizeOptions(propOptions);

        // Set initial value
        if (!value && options[0] && options[0].value) {
            const initialValue = options[0].value;
            this.setState({ value: initialValue });
            onChange(model, initialValue);
        }

        return options;
    };

    /**
     * Change input
     * Renders the local state and sends the new value up
     */
    onChange = (event) => {
        const { onChange, model } = this.props;
        const value = event.target.value;

        this.setState({ value: value });
        onChange(model, value);
    };

    /**
     * Radio button changed
     * @param {*} event
     */
    onChangeRadio = (event) => {};

    render() {
        const { readOnly, extended } = this.props;
        const { value = '' } = this.state;

        const options = this.getOptions();

        // Read only, show value
        if (readOnly) {
            let val = options.filter((item) => item.value == value);
            if (val) {
                val = val[0];
            }
            return <div>{val}</div>;
        }
        // Extended radio list with title and description
        else if (extended) {
            return (
                <div className="form-flow__field__select">
                    {options &&
                        options.map &&
                        options.map((item, i) => (
                            <div className="form-flow__field__select__item" key={i}>
                                <input type="radio" id={'radio-' + i} onChange={this.onChange} value={item.value} checked={value === item.value} />
                                <label htmlFor={'radio-' + i}>
                                    <div className="form-flow__field__select__item__icon">
                                        <Icon>{item.icon}</Icon>
                                    </div>
                                    <div className="form-flow__field__select__item__context">
                                        <div className="form-flow__field__select__item__title">{item.label}</div>
                                        <div className="form-flow__field__select__item__description">{item.description}</div>
                                    </div>
                                </label>
                            </div>
                        ))}
                </div>
            );
        }
        // Not read only, show inout
        else {
            return (
                <div className="form-flow__field__select">
                    <FormControl variant="outlined">
                        <Select native value={value} onChange={this.onChange}>
                            {options.map((item, i) => (
                                <option value={item.value} key={i}>
                                    {item.label}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            );
        }
    }
}
