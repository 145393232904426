import FieldMapDynamicFields from './components';
import  FieldSetWrapper  from '../../components/fieldset';

const FieldSetFieldMapDynamicFields = FieldSetWrapper(FieldMapDynamicFields);

export default FieldSetFieldMapDynamicFields;
export {
    FieldMapDynamicFields,
    FieldSetFieldMapDynamicFields
}
