import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MuiRadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from 'components/ui-components-v2/Radio';
import FormFlowHelpers from 'components/data/FormFlowHelpers';

import '../styles/main.scss';

/**
 * Select class
 * Displays a select box
 */
export default class RadioGroup extends Component {
    static propTypes = {
        value: PropTypes.string,
        options: PropTypes.any, // The differrent options as object or array
        readOnly: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value ? props.value : ''
        };
    }

    /**
     * Get Options
     * This class prepares the options in the form.
     * Various formats are supported:
     * - K/V Pairs as an object
     * - Objects with id/title fields
     * - Objects with id/name fields
     * - Objects with value/label fields
     */
    getOptions = () => {
        const { options: propOptions } = this.props;
        const { value } = this.state;
        let options = [];

        // We have an empty value. Add the empty option as well to prevent an incorrect value from being displayed in the list.
        if (!value) {
            options.push({ value: '', label: '' });
        }

        options = FormFlowHelpers.standardizeOptions(propOptions);

        return options;
    };

    /**
     * Change input
     * Renders the local state and sends the new value up
     */
    onChange = (event) => {
        const { onChange, model } = this.props;
        const value = event.target.value;

        this.setState({ value: value });
        onChange(model, value);
    };

    render() {
        const { readOnly } = this.props;
        const { value = '' } = this.state;

        const options = this.getOptions();

        // Read only, show value
        if (readOnly) {
            let val = options.filter((item) => item.value == value);
            if (val) {
                val = val[0];
            }
            return <div>{val}</div>;
        }
        // Not read only, show inout
        else {
            return (
                <div className="form-flow__field__radio-group">
                    <MuiRadioGroup onChange={this.onChange} value={value}>
                        {options.map((item, i) => (
                            <FormControlLabel key={i} value={item.value} control={<Radio />} label={item.label} />
                        ))}
                    </MuiRadioGroup>
                </div>
            );
        }
    }
}
