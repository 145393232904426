import Text from './components';
import  FieldSetWrapper  from '../../components/fieldset';

const FieldSetText = FieldSetWrapper(Text);

export default FieldSetText;
export {
    Text,
    FieldSetText
}
