import React, { Component } from 'react';

/**
 * Checkbox class
 * Displays a checkbox
 */
export default class Empty extends Component {
    constructor(props) {
        super(props);

        this.state = {
            first: true
        };
    }

    componentDidMount = (prevProps, prevState) => {
        this.onChange();
    };

    /**
     * Change input
     * Renders the local state and sends the new value up
     */
    onChange = () => {
        const { onChange, model } = this.props;
        const { first } = this.state;

        if (!first) return;

        this.setState({ first: false });
        onChange(model, undefined);
    };

    render() {
        return null;
    }
}
