import React from 'react';
import cN from 'classnames';
import Typography from 'components/ui-components-v2/Typography';
import Context from './context';
import '../styles/fieldset.scss';

/**
 * FieldSetWrapper
 * This components wraps the FieldSet component around the passed component
 * @param {*} WrappedComponent
 */
function FieldSetWrapper(WrappedComponent) {
    /**
     * This is a wrapper for the fieldset. A component get wrapped with this component.
     */
    class FieldSetDecorator extends React.Component {
        static contextType = Context;

        constructor(props, context) {
            super(props);
            this.state = {
                value: ''
            };
        }

        /**
         * Get Value from model.
         * E.g. when the model is 'item.header.title' this fetches it from the data in the context.
         */
        getValueFromModel() {
            const { data } = this.context; // eslint-disable-line
            const { model } = this.props;
            let value;
            // Get value from model and set new stat
            try {
                value = eval('data.' + model);
            } catch (e) { }
            return value;
        }

        render() {
            const { title, description, validators, label, variant, type } = this.props;
            const { validator } = this.context;
            const value = this.getValueFromModel();

            return (
                <div className={cN('form-flow__field-set', variant === 'compact' && 'form-flow__field-set--compact')}>
                    {(variant !== 'compact' || (variant === 'compact' && type !== 'switch')) && (
                        <React.Fragment>
                            {title && <Typography variant="body2">{title}</Typography>}

                            {description && <div className="form-flow__field-set__description">{description}</div>}
                        </React.Fragment>
                    )}

                    <div className="form-flow__field-set__contents">
                        <WrappedComponent value={value} {...this.props} />
                    </div>

                    {validators && (
                        <React.Fragment>{validator.message(title, value, validators, { className: this.props['data-cy'] + '-validator' })}</React.Fragment>
                    )}

                    {label && <div className="form-flow__field-set__label">{label}</div>}
                </div>
            );
        }
    }

    return FieldSetDecorator;
}

export default FieldSetWrapper;
