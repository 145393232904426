import FieldSetWrapper from '../../components/fieldset';
import MultiLevelCheckboxList from './components';

interface MultiLevelCheckboxListOption {
    label: string;
    value: string;
    children?: MultiLevelCheckboxListOption[];
    icon?: JSX.Element | string;
}

const FieldSetSelect = FieldSetWrapper(MultiLevelCheckboxList);

export default FieldSetSelect;
export { MultiLevelCheckboxList, FieldSetSelect };
export type { MultiLevelCheckboxListOption };
