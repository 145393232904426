import Select from './components';
import  FieldSetWrapper  from '../../components/fieldset';

const FieldSetSelect = FieldSetWrapper(Select);

export default FieldSetSelect;
export {
    Select,
    FieldSetSelect
}
