import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import ColorPicker from 'components/ui-components/ColorPicker';
import getColorPickerObject from 'components/input/ColorSelector/utils/getColorPickerObject';
import cloneDeep from 'helpers/cloneDeep';
import '../styles/main.scss';

const DynamicColorList = (props) => {
    const getDefaultValue = () => {
        if (!props.defaultValue) return [];
        if (props.useGradients === false) {
            return props.defaultValue.filter((color) => !color.gradientColor).map((color) => getColorPickerObject(color.color));
        } else {
            return props.defaultValue.map((color) => getColorPickerObject(color.color));
        }
    };

    const [colors, setColors] = useState(props.value || getDefaultValue());

    //If the default value is used send it to the component above.
    useEffect(() => {
        if (!props.value) {
            props.onChange(props.model, getDefaultValue());
        }
    }, []);

    const onColorInput = (index, value) => {
        const newColors = cloneDeep(colors);
        newColors[index] = value;

        setColors(newColors);
        props.onChange(props.model, newColors);
    };

    const removeColor = (index) => {
        const newColors = cloneDeep(colors);
        newColors.splice(index, 1);

        setColors(newColors);
        props.onChange(props.model, newColors);
    };

    const addColor = () => {
        const newColors = cloneDeep(colors);
        newColors.push(getColorPickerObject('#ffffffff'));

        setColors(newColors);
        props.onChange(props.model, newColors);
    };

    const getPresetColors = () => {
        if (!props.presetColors) return [];
        if (props.useGradients === false) {
            return props.presetColors.filter((color) => !color.gradientColor);
        } else {
            return props.presetColors;
        }
    };

    return (
        <div className="form-flow__field__dynamic-color-list">
            {colors.map((color, i) => {
                const correctColor = (() => {
                    if (typeof color === 'string') {
                        return getColorPickerObject(color);
                    }

                    return color;
                })();

                return (
                    <div key={i} className="form-flow__field__dynamic-color-list__color">
                        <ColorPicker
                            color={correctColor}
                            useGradients={props.useGradients}
                            onChange={(value) => onColorInput(i, value)}
                            presetColors={getPresetColors()}
                        />
                        <IconButton onClick={() => removeColor(i)} size="small" className="delete-color">
                            <Icon>delete</Icon>
                        </IconButton>
                    </div>
                );
            })}

            <Button className="form-flow__field__dynamic-color-list__add-color" onClick={addColor} color="primary" size="small" variant="outlined">
                Add color
            </Button>
        </div>
    );
};

export default DynamicColorList;
