import Tags from './components';
import  FieldSetWrapper  from '../../components/fieldset';

const FieldSetTags = FieldSetWrapper(Tags);

export default FieldSetTags;
export {
    Tags,
    FieldSetTags
}
