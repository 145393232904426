import React, { useState } from 'react';
import Accordion from 'components/ui-components-v2/Accordion';
import AccordionSummary from 'components/ui-components-v2/AccordionSummary';
import AccordionDetails from 'components/ui-components-v2/AccordionDetails';
import Icon from 'components/ui-components-v2/Icon';
import '../styles/sub-section.scss';
import { FormInput } from '../types/types';

interface SubSectionProps {
    item: FormInput;
    renderItems: (items: FormInput[]) => React.ReactElement;
}

const SubSection = ({ item, renderItems }: SubSectionProps) => {
    const [open, setOpen] = useState<boolean>(false);

    const handleOpenStateChange = (event: React.SyntheticEvent, open: boolean) => {
        setOpen(open);
    };

    return (
        <Accordion onChange={handleOpenStateChange} expanded={open} disableGutters={true} className="form-flow__sub-section">
            <AccordionSummary>
                <div className="form-flow__sub-section__header">
                    <Icon fontSize="small" className="form-flow__sub-section__header__icon">
                        {open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                    </Icon>
                    <span className="form-flow__sub-section__header__title">{item.title}</span>
                </div>
            </AccordionSummary>
            <AccordionDetails className="form-flow__sub-section__details">{renderItems(item.items)}</AccordionDetails>
        </Accordion>
    );
};

export default SubSection;
