import Datetime from 'react-datetime';
import moment from 'moment';
import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';
import '../styles/main.scss';

/**
 * Field Date
 * Displays the date field
 */
export default class FieldDate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
    }

    onChange = (momentDate) => {
        const { onChange, model } = this.props;

        const value = typeof momentDate === 'string' || !momentDate || momentDate === '' ? momentDate : momentDate.format('YYYY-MM-DD');
        this.setState({ value: value });
        onChange(model, value);
    };

    render() {
        let { value = null } = this.state;
        const { closeOnSelect = true } = this.props;

        // A string was submitted
        if (typeof value === 'string' && value !== '') {
            value = moment(value);
            value = value.format('YYYY-MM-DD');
        }
        // A timestmap was used
        else if (typeof value === 'number' && value > 1000) {
            value = moment(value * 1000);
            value = value.format('YYYY-MM-DD');
        } else {
            value = moment().format('YYYY-MM-DD');
        }

        return (
            <div className="form-flow__field__date">
                <Datetime
                    closeOnSelect={closeOnSelect}
                    timeFormat={false}
                    dateFormat="YYYY-MM-DD"
                    defaultValue={value}
                    inputProps={{ className: 'form-flow__field__input' }}
                    onChange={this.onChange}
                />
            </div>
        );
    }
}
