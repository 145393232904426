import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from 'components/ui-components-v2/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormFlowHelpers from 'components/data/FormFlowHelpers';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';

import '../styles/main.scss';

/**
 * Select class
 * Displays a select box
 */
export default class CheckboxList extends Component {
    static propTypes = {
        value: PropTypes.any, // The differrent options as object or array
        options: PropTypes.any, // The differrent options as object or array
        readOnly: PropTypes.bool,
        outputType: PropTypes.string,
        canSelectAll: PropTypes.bool
    };

    constructor(props) {
        super(props);

        let value = [];

        if (props.value) {
            if (props.outputType === 'keyvalue') {
                value = Object.keys(props.value).map((key) => key);
            } else if (props.outputType === 'object') {
                value = props.value.map((v) => v.key);
            } else {
                value = Array.isArray(props.value) ? props.value : [];
            }
        }

        this.state = {
            value
        };
    }

    /**
     * Get Options
     * This class prepares the options in the form.
     * Various formats are supported:
     * - K/V Pairs as an object
     * - Objects with id/title fields
     * - Objects with id/name fields
     * - Objects with value/label fields
     */
    getOptions = () => {
        const { options: propOptions } = this.props;
        const { value } = this.state;
        let options = [];

        // We have an empty value. Add the empty option as well to prevent an incorrect value from being displayed in the list.
        if (!value) {
            options.push({ value: '', label: '' });
        }

        options = FormFlowHelpers.standardizeOptions(propOptions);

        return options;
    };

    /**
     * Change input
     * Renders the local state and sends the new value up
     */
    onChange = (event) => {
        const { onChange, model, outputType, options } = this.props;

        if (!event?.target?.value) return;

        const selectedItem = event.target.value;
        const value = [...this.state.value];

        if (!value.includes(selectedItem)) {
            value.push(selectedItem);
        } else {
            value.splice(value.indexOf(selectedItem), 1);
        }

        this.setState({ value });

        if (outputType === 'object') {
            const array = value.map((key) => ({ key, value: options[key] || key }));
            onChange(model, array);
        } else if (outputType === 'keyvalue') {
            let object = {};
            // convert array to key value object
            value.forEach((key) => {
                object = { ...object, [key]: options[key] || key };
            });
            // add new values to value
            onChange(model, object);
        } else {
            onChange(model, value);
        }
    };

    selectAll = () => {
        const { onChange, model, outputType, options } = this.props;
        const newValues = this.getOptions().map((item) => item.value);

        this.setState({ value: newValues });

        if (outputType === 'object') {
            const array = newValues.map((key) => ({ key, value: options[key] || key }));
            onChange(model, array);
        } else if (outputType === 'keyvalue') {
            let object = {};
            // convert array to key value object
            newValues.forEach((key) => {
                object = { ...object, [key]: options[key] || key };
            });
            // add new values to value
            onChange(model, object);
        } else {
            onChange(model, newValues);
        }
    };

    deselectAll = () => {
        const { model } = this.props;

        this.setState({ value: [] });
        this.onChange(model, []);
    };

    shouldComponentUpdate() {
        return true;
    }

    render() {
        const { readOnly, canSelectAll } = this.props;
        const { value = [] } = this.state;

        const options = this.getOptions();

        // Read only, show value
        if (readOnly) {
            let val = options.filter((item) => item.value === value);
            if (val) {
                val = val[0];
            }
            return <div>{val}</div>;
        }
        // Not read only, show inout
        else {
            return (
                <div className="form-flow__field__checkbox-list">
                    {canSelectAll && options.length > 5 && value.length !== options.length && (
                        <Button
                            size="small"
                            className="form-flow__field__checkbox-list__select-all"
                            variant="outlined"
                            color="primary"
                            onClick={() => this.selectAll()}>
                            {Translation.get('labels.selectAll', 'common')}
                        </Button>
                    )}
                    {canSelectAll && options.length > 5 && value.length === options.length && (
                        <Button
                            size="small"
                            className="form-flow__field__checkbox-list__select-all"
                            variant="outlined"
                            color="primary"
                            onClick={() => this.deselectAll()}>
                            {Translation.get('labels.deselectAll', 'common')}
                        </Button>
                    )}
                    <FormGroup>
                        {options &&
                            options.map &&
                            options.map((item, i) => (
                                <FormControlLabel
                                    key={i}
                                    control={<Checkbox checked={value.includes(item.value)} onClick={this.onChange} value={item.value} />}
                                    label={item.label}
                                />
                            ))}
                    </FormGroup>
                </div>
            );
        }
    }
}
