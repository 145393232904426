import Checkbox from './components';
import  FieldSetWrapper  from '../../components/fieldset';

const FieldSetCheckbox = FieldSetWrapper(Checkbox);

export default FieldSetCheckbox;
export {
    Checkbox,
    FieldSetCheckbox
}
