import React from 'react';
import '../styles/main.scss';

/**
 * Divides an item into three columns
 */
export default class Columns extends React.Component {

  render() {
    const {
      children,
      count
    } = this.props;

    // Columns
    let columns=[];
    for (var i=0;i<count;i++) {
        columns.push([]);
    }

    children.forEach((item,i)=>{
        columns[i%count].push(item);
    });

    return (

        <div className="columns">
            {columns.map((column,i)=>(
                <div className="columns__item" key={'column_'+i}>
                    {column.map((item)=>item)}
                </div>
            ))}
        </div>
    )
  }
  


}
