import PropTypes from 'prop-types';
import React, { Component } from 'react';

/**
 * Display class
 * Displays the value
 */
export default class Display extends Component {
    static propTypes = {
        value: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
    }

    render() {
        const { value = '' } = this.state;

        return <div className="form-flow__field__value">{value}</div>;
    }
}
