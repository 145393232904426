import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import '../styles/main.scss';

/**
 * JSON class
 * Displays a JSON field
 */
export default class JSONField extends Component {
    static propTypes = {
        value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
        readOnly: PropTypes.bool,
        outputType: PropTypes.string,
        editor: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? props.value : '',
            parseError: null
        };

        this.state.value = props.value;
        this.latestValue = this.state.value;
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!this.props.value && prevProps.value && this.state.value) {
        } else if (!this.props.value && prevProps.value && this.state.value) {
            this.setState({ value: '' });
        }
    };

    /**
     * Change input
     * Renders the local state and sends the new value up
     */
    onChange = (newValue) => {
        if (this.updateTimeout) {
            clearTimeout(this.updateTimeout);
        }

        this.latestValue = newValue;
        this.setState({ value: newValue });
        this.updateTimeout = setTimeout(this.onChangeProcess, 500);
    };

    onChangeProcess = () => {
        const { onChange, model } = this.props;
        onChange(model, this.latestValue);
    };

    render() {
        const { value = '', parseError } = this.state;

        return (
            <div className="form-flow__field__json">
                <AceEditor
                    mode="html"
                    width={'100%'}
                    theme="github"
                    onChange={this.onChange}
                    value={value ? value : ''}
                    editorProps={{ $blockScrolling: true }}
                    setOptions={{ useWorker: false }}
                />
                {parseError && <div className="form-flow__field__json__error">{parseError}</div>}
            </div>
        );
    }
}
