import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import DOMPurify from 'dompurify';
import Translation from 'components/data/Translation';
import VisualEditor from './visual-editor';
import '../styles/main.scss';

/**
 * JSON class
 * Displays a JSON field
 */
export default class JSONField extends Component {
    static propTypes = {
        value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
        readOnly: PropTypes.bool,
        outputType: PropTypes.string,
        editor: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? props.value : '',
            parseError: null
        };

        // We passed an object, stringify the value
        if (typeof props.value !== 'string' && props.value) {
            this.state.value = JSON.stringify(props.value, undefined, 4);
        }
        // We have a string, make it pretty
        else if (typeof props.value === 'string' && props.value) {
            try {
                const obj = JSON.parse(props.value);
                this.initialValue = props.value;
                this.state.value = JSON.stringify(obj, undefined, 4);
            } catch (e) {
                console.warn(Translation.get('formflow.SON.invalidInput', 'common'));
            }
        }
        this.latestValue = this.state.value;
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!this.props.value && prevProps.value && this.state.value) {
        } else if (!this.props.value && prevProps.value && this.state.value) {
            this.setState({ value: '' });
        }
    };

    /**
     * Change input
     * Renders the local state and sends the new value up
     */
    onChange = (newValue) => {
        if (this.updateTimeout) {
            clearTimeout(this.updateTimeout);
        }

        try {
            const parsedJson = JSON.parse(newValue);
            if (parsedJson) this.setState({ parseError: null });
        } catch (error) {
            this.setState({
                parseError: Translation.get('formflow.JSON.invalidInputError', 'common', { error: error })
            });
        }

        this.latestValue = newValue;
        this.setState({ value: newValue });
        this.updateTimeout = setTimeout(this.onChangeProcess, 500);
    };

    onChangeProcess = () => {
        const { onChange, model, outputFormat = 'json' } = this.props;
        if (outputFormat === 'string') {
            onChange(model, this.latestValue);
        } else {
            onChange(model, JSON.parse(this.latestValue));
        }
    };

    render() {
        const { readOnly, editor = '' } = this.props;
        const { value = '', parseError } = this.state;

        // Read only, show value
        if (readOnly) {
            return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }} />;
        }
        // Show a visual editor
        else if (editor === 'visual') {
            return (
                <div className="form-flow__field__json">
                    <VisualEditor value={value ? value : ''} onChange={this.onChange} />
                    {parseError && <div className="form-flow__field__json__error">{parseError}</div>}
                </div>
            );
        }
        // Not read only, show inout
        else {
            return (
                <div className="form-flow__field__json">
                    <AceEditor
                        mode="json"
                        width={'100%'}
                        theme="github"
                        onChange={this.onChange}
                        value={value ? value : ''}
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{ useWorker: false }}
                    />
                    {parseError && <div className="form-flow__field__json__error">{parseError}</div>}
                </div>
            );
        }
    }
}
