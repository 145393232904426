import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TextField from 'components/ui-components-v2/TextField';
import '../styles/main.scss';

/**
 * Copy class
 * Displays a textinput
 */
export default class Copy extends Component {
    static propTypes = {
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        multiline: PropTypes.bool,
        readOnly: PropTypes.bool,
        maxLength: PropTypes.number,
        fieldType: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { onChange, model, value } = this.props;
        // External updates
        if (this.props.value != prevProps.value && prevProps.value == this.state.value) {
            this.setState({ value: this.props.value });
            //update form flow data
            onChange(model, value);
        }
        // The value was externally reset
        else if (!this.props.value && prevProps.value && this.state.value) {
            this.setState({ value: '' });
            //update form flow data
            onChange(model, value);
        }
    };
    /**
     * Cap the value to the min and max
     * @param val value to cap
     * @returns capped value
     */
    capValue = (val) => {
        const { min, max } = this.props;
        let newVal = typeof val === 'string' ? parseInt(val) : val;
        if (newVal < parseInt(min)) {
            newVal = parseInt(min);
        }
        if (newVal > parseInt(max)) {
            newVal = parseInt(max);
        }

        return newVal.toString();
    };

    /**
     * Change input
     * Renders the local state and sends the new value up
     */
    onChange = (event) => {
        const { onChange, model, min, max, fieldType } = this.props;
        const value = event.target.value;
        if (fieldType === 'number' && (typeof min === 'number' || typeof max === 'number')) {
            //Only cap values if min or max is set
            const cappedVal = this.capValue(value);

            if (value === cappedVal) {
                onChange(model, value);
            }
        } else {
            onChange(model, value);
        }
        this.setState({ value: value });
    };

    /**
     * Checks if the value needs to be capped and if so caps it.
     */
    onBlur = () => {
        const { onChange, min, max, fieldType } = this.props;
        const { value } = this.state;
        if (fieldType !== 'number' || !(typeof min === 'number' || typeof max === 'number')) return;

        const cappedVal = this.capValue(value);
        if (cappedVal !== value) {
            onChange(cappedVal);
            this.setState({ value: cappedVal });
        }
    };

    render() {
        const { maxLength, readOnly, multiline, fieldType = 'text', autocomplete, startAdornment, endAdornment, variant, placeholder = '' } = this.props;
        const { value = '' } = this.state;

        // Read only, show value
        if (readOnly) {
            return <div>{value}</div>;
        }
        // Not read only, show inout
        else {
            let inputProps = {};
            if (maxLength) {
                inputProps = {
                    maxLength: maxLength ? maxLength : 1000,
                    endAdornment: <InputAdornment position="end">{maxLength ? `${maxLength - value.length}` : ''}</InputAdornment>
                };
            }
            if (startAdornment) {
                inputProps.startAdornment = <InputAdornment position="start">{startAdornment}</InputAdornment>;
            }
            if (endAdornment) {
                inputProps.endAdornment = <InputAdornment position="end">{endAdornment}</InputAdornment>;
            }

            // Disable autocomplete on password if needed
            let formProps = {};
            if (autocomplete === 'off') {
                formProps = {
                    autocomplete: 'false'
                };
            }

            return (
                <div className="form-flow__field__copy">
                    <TextField
                        data-cy={this.props['data-cy']}
                        className="form-flow__field__copy__input"
                        value={value}
                        type={fieldType}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        margin="dense"
                        autoComplete={autocomplete === 'off' ? 'off' : 'on'}
                        onInput={(e) => {
                            if (e.target.value && maxLength && maxLength > 0) {
                                e.target.value = e.target.value.slice(0, maxLength);
                            }
                        }}
                        multiline={multiline}
                        placeholder={placeholder}
                        variant="outlined"
                        form={formProps}
                        style={{ marginTop: 0 }}
                        InputProps={inputProps}
                    />
                </div>
            );
        }
    }
}
