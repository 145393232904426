import React, { useState } from 'react';
import '../styles/main.scss';
import MultiLevelCheckboxListInput from 'components/input/MultiLevelCheckboxList/components/index';
import { MultiLevelCheckboxListOption } from '..';

interface MultiLevelCheckboxListProps {
    initialValue: MultiLevelCheckboxListOption[];
    value: MultiLevelCheckboxListOption[];
    options: MultiLevelCheckboxListOption[];
    onChange: (model: string, value: MultiLevelCheckboxListOption[]) => void;
    model?: string;
    compact?: boolean;
}

/**
 * Select class
 * Displays a select box
 */
const MultiLevelCheckboxList: React.FC<MultiLevelCheckboxListProps> = ({ initialValue = [], value = [], options, onChange, model, compact = false }) => {
    const onMutation = (value: any) => {
        onChange(model ? model : '', value.checkedItems);
    };
    return (
        <div className="form-flow__field__checkbox-list">
            <MultiLevelCheckboxListInput items={options as Array<any>} value={{ checkedItems: value as any }} onMutation={onMutation} identifier="key" />
        </div>
    );
};

export default MultiLevelCheckboxList;
