import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from 'components/ui-components-v2/TextField';
import Select from 'components/ui-components-v2/Select';
import Switch from 'components/ui-components-v2/Switch';
import '../styles/main.scss';

/**
 * FieldMapDynamicFields class
 * Displays a row source items and input fields
 * This is used to map the value of the dynamic templates to the values of the new records in the FeedManager
 */
export default class FieldMapDynamicFields extends Component {
    static propTypes = {
        value: PropTypes.arrayOf(
            PropTypes.shape({
                // The rows of source items and inputs
                title: PropTypes.string, // The title/label of the template source item
                model: PropTypes.string, // The model that the template source item uses
                type: PropTypes.string, // The type of the input field
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]), // The value of the input field
                manualValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]) // The value of the manual input field, if there is one
            })
        ),
        dynamicFields: PropTypes.arrayOf(
            PropTypes.shape({
                // The dynamic fields of a template. These will be pushed to the value array
                title: PropTypes.string, // The title/label of the template source item
                model: PropTypes.string, // The model that the template source item uses
                type: PropTypes.string // The type of the input field
            })
        ),
        selectOptions: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string
            })
        )
    };

    static defaultProps = {
        value: [],
        dynamicFields: [],
        selectOptions: []
    };

    constructor(props) {
        super(props);

        let value = [];

        if (props.value) {
            value = props.value;
        } else if (props.dynamicFields && props.dynamicFields.length > 0) {
            props.dynamicFields.forEach((field) => {
                value.push({
                    title: field.title,
                    model: field.model,
                    type: field.type,
                    value: ''
                });
            });
        }

        this.state = {
            value
        };
    }

    componentDidUpdate = (prevProps) => {
        const { dynamicFields } = this.props;

        if (prevProps.dynamicFields !== dynamicFields) {
            const value = [];

            dynamicFields.forEach((field) => {
                value.push({
                    title: field.title,
                    model: field.model,
                    type: field.type,
                    value: ''
                });
            });

            this.setState({ value });
        }
    };

    onChangeModel = () => {
        const { model, onChange } = this.props;
        const { value } = this.state;

        onChange(model, value);
    };

    /**
     * Change input
     * Renders the local state and sends the new value up
     */
    onChange = (event, row, manual = false) => {
        const { value } = this.state;
        const newValue = [...value];

        const inputValue = row.type === 'switch' && manual ? event.target.checked : event.target.value;

        const targetIndex = value.findIndex((item) => item.model === row.model);
        newValue[targetIndex][manual ? 'manualValue' : 'value'] = inputValue;

        this.setState({ value: newValue }, () => this.onChangeModel());
    };

    addInputRow = () => {
        const { value } = this.state;

        const newValue = [...value];

        newValue.push({
            fieldName: '',
            mappingName: ''
        });

        this.setState({ value: newValue }, () => this.onChange());
    };

    removeInputRow = (i) => {
        const { value } = this.state;

        const newValue = [...value];
        newValue.splice(i, 1);

        this.setState({ value: newValue }, () => this.onChange());
    };

    render() {
        let { selectOptions } = this.props;
        const { value = [] } = this.state;

        selectOptions = [...[{ value: '', label: 'No value' }], ...selectOptions, ...[{ value: 'manualValue', label: 'Manual value' }]];

        return (
            <div className="form-flow__field__field-map-dynamic-fields-dynamic-fields">
                {value.map((inputRow, index) => {
                    return (
                        <div key={index}>
                            <div className="form-flow__field__field-map-dynamic-fields__inputs">
                                <div className="form-flow__field__field-map-dynamic-fields__inputs__source">
                                    <div className="form-flow__field__field-map-dynamic-fields__inputs__source__title">{inputRow.title}</div>
                                    <div className="form-flow__field__field-map-dynamic-fields__inputs__source__subtitle">
                                        {inputRow.model} | {inputRow.type}
                                    </div>
                                </div>

                                <div className="form-flow__field__field-map-dynamic-fields__inputs__mapname">
                                    <FormControl variant="outlined" fullWidth>
                                        <Select
                                            className="form-flow__field__field-map-dynamic-fields__inputs__mapname__input"
                                            native
                                            inputProps={{ style: { height: 34 } }}
                                            fullWidth
                                            value={value[index].value}
                                            onChange={(e) => this.onChange(e, inputRow)}>
                                            {selectOptions.map((item, i) => (
                                                <option value={item.value} key={i}>
                                                    {item.label}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            {inputRow.value === 'manualValue' && (
                                <div className="form-flow__field__field-map-dynamic-fields__manual-value">
                                    <div className="form-flow__field__field-map-dynamic-fields__manual-value__arrow-wrapper">
                                        <div className="form-flow__field__field-map-dynamic-fields__manual-value__arrow-wrapper__arrow"></div>
                                    </div>
                                    {inputRow.type === 'text' && (
                                        <TextField
                                            className="form-flow__field__field-map-dynamic-fields__manual-value__input"
                                            value={inputRow.manualValue}
                                            onChange={(e) => this.onChange(e, inputRow, true)}
                                            margin="dense"
                                            variant="outlined"
                                            style={{ marginTop: 0, marginRight: 16 }}
                                        />
                                    )}
                                    {inputRow.type === 'switch' && (
                                        <React.Fragment>
                                            <Switch
                                                className="form-flow__field__field-map-dynamic-fields__manual-value__input"
                                                onChange={(e) => this.onChange(e, inputRow, true)}
                                                checked={inputRow.manualValue}
                                            />
                                            <div className="form-flow__field__field-map-dynamic-fields__manual-value__subtext">
                                                {inputRow.manualValue ? 'Enabled' : 'Disabled'}
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
}
