import AssetGalleryInput from 'components/input/AssetGalleryInput';
import Text from '../fields/Text';
import SubTitle from '../fields/SubTitle';
import Date from '../fields/Date';
import DateRange from '../fields/DateRange';
import DateTime from '../fields/DateTime';
import Richtext from '../fields/Richtext';
import File from '../fields/File';
import Display from '../fields/Display';
import Checkbox from '../fields/Checkbox';
import Select from '../fields/Select';
import JSON from '../fields/JSON';
import JS from '../fields/JS';
import HTML from '../fields/HTML';
import TextArea from '../fields/Textarea';
import CheckboxList from '../fields/CheckboxList';
import MultiLevelCheckboxList from '../fields/MultiLevelCheckboxList';
import RadioGroup from '../fields/RadioGroup';
import Chips from '../fields/Chips';
import FieldMap from '../fields/FieldMap';
import FieldMapDynamicFields from '../fields/FieldMapDynamicFields';
import Switch from '../fields/Switch';
import Autocomplete from '../fields/Autocomplete';
import DynamicColorList from '../fields/DynamicColorList';
import DynamicOptionsList from '../fields/DynamicOptionsList';
import Divider from '../fields/Divider';
import AlertBox from '../fields/AlertBox';

/**
 * This file exports all possible field values as an array
 */
export default {
    // eslint-disable-line
    text: Text,
    richtext: Richtext,
    date: Date,
    dateRange: DateRange,
    dateTime: DateTime,
    subtitle: SubTitle,
    file: File,
    display: Display,
    select: Select,
    checkbox: Checkbox,
    json: JSON,
    js: JS,
    html: HTML,
    textarea: TextArea,
    checkboxList: CheckboxList,
    multiLevelCheckboxList: MultiLevelCheckboxList,
    radioGroup: RadioGroup,
    chips: Chips,
    fieldMap: FieldMap,
    fieldMapDynamicFields: FieldMapDynamicFields,
    switch: Switch,
    autocomplete: Autocomplete,
    dynamicColorList: DynamicColorList,
    dynamicOptionsList: DynamicOptionsList,
    divider: Divider,
    alertBox: AlertBox,
    assetGalleryInput: AssetGalleryInput
};
