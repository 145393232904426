import PropTypes from 'prop-types';
import React, { Component } from 'react';
import '../styles/main.scss';

/**
 * Textarea class
 * Displays a textarea
 */
export default class TextareaField extends Component {
    static propTypes = {
        value: PropTypes.string,
        readOnly: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!this.props.value && prevProps.value && this.state.value) {
        } else if (!this.props.value && prevProps.value && this.state.value) {
            this.setState({ value: '' });
        }
    };

    /**
     * Change input
     * Renders the local state and sends the new value up
     */
    onChange = (event) => {
        const { onChange, model } = this.props;
        const value = event.target.value;

        this.setState({ value: value });
        onChange(model, value);
    };

    render() {
        const { readOnly } = this.props;
        const { value = '' } = this.state;

        // Read only, show value
        if (readOnly) {
            return <div>{value}</div>;
        }
        // Not read only, show inout
        else {
            return (
                <div className="form-flow__field__textarea">
                    <textarea className="form-flow__field__textarea__input" value={value} onChange={this.onChange} />
                </div>
            );
        }
    }
}
