export default [ // eslint-disable-line
    {
        'value': 'none',
        'label': 'None'
    },
    {
        'value': 'eq',
        'label': '=='
    },
    {
        'value': 'gt',
        'label': '>'
    },
    {
        'value': 'gte',
        'label': '>='
    },
    {
        'value': 'lt',
        'label': '<'
    },
    {
        'value': 'lte',
        'label': '<='
    },
    {
        'value': 'ne',
        'label': '!='
    },
    {
        'value': 'in',
        'label': 'Matches'
    },
    {
        'value': 'nin',
        'label': 'Does not match'
    }
]
