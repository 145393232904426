import Checkbox from 'components/ui-components-v2/Checkbox';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import '../styles/main.scss';

/**
 * Checkbox class
 * Displays a checkbox
 */
export default class CheckboxField extends Component {
    static propTypes = {
        value: PropTypes.oneOf(['0', '1', true, false]),
        readOnly: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        };
        if (typeof props.value === 'string') {
            this.state.value = props.value == '0' ? false : true;
        }
    }

    componentDidUpdate = (prevProps, prevState) => {};

    /**
     * Change input
     * Renders the local state and sends the new value up
     */
    onChange = (event) => {
        const { onChange, model } = this.props;
        const { value } = this.state;

        this.setState({ value: !value });
        onChange(model, !value);
    };

    render() {
        const { readOnly } = this.props;
        const { value = false } = this.state;

        // Read only, show value
        if (readOnly) {
            return <div className="form-flow__field__checkbox">{value ? 'Yes' : 'No'}</div>;
        }
        // Not read only, show inout
        else {
            return (
                <div className="form-flow__field__checkbox">
                    <Checkbox className="form-flow__field__checkbox__input" checked={value} onClick={this.onChange} />
                </div>
            );
        }
    }
}
