import Switch from './components';
import  FieldSetWrapper  from '../../components/fieldset';

const FieldSetSwitch = FieldSetWrapper(Switch);

export default FieldSetSwitch;
export {
    Switch,
    FieldSetSwitch
}
